/*-----------------------------------------------------------------------------*
 *  PASSWORD MASK
 *-----------------------------------------------------------------------------*
 *
 *  Hide and reveal password input to allow users to see what they are typing.
 *
 *  @author  Luca Rosaldi
 *  @version 1.0.0
 *
 */

( function( global ) {
  "use strict";

  /**
   * @type {Object} Default settings for the module.
   */
  const defaults = {
    lang: ( document.documentElement.getAttribute( 'lang' ) || navigator.language || navigator.userLanguage ).substr( 0, 2 ),
    strings: {
      show: {
        en: 'Show password',
        fr: 'Montrer mot de passe',
        de: 'Passwort anzeigen',
        es: 'Mostrar contraseña',
        it: 'Mostra password',
        pt: 'Mostrar senha'
      },
      hide: {
        en: 'Hide password',
        fr: 'Cacher mot de passe',
        de: 'Passwort verbergen',
        es: 'Esconder contraseña',
        it: 'Nascondi password',
        pt: 'Esconder a senha'
      }
    }
  };

  /**
   * Constructor.
   *
   * @param {Node}   input
   * @param {Object} settings
   */
  function PasswordMask( input, settings = {} ) {
    this.input = input;
    this.settings = Object.assign( defaults, settings );

    this.wrapper = document.createElement( 'div' );
    this.wrapper.style.position = 'relative';
    this.wrapper.style.width = '100%';

    this.trigger = document.createElement( 'button' );
    this.trigger.type = 'button';
    this.trigger.title = this.getTriggerTitle();

    this.toggle = this.toggle.bind( this );

    this.init();
  }

  /**
   * Initialize module.
   */
  PasswordMask.prototype.init = function() {

    // wrap the input and insert the trigger element
    this.input.parentNode.insertBefore( this.wrapper, this.input );
    this.wrapper.appendChild( this.input );
    this.wrapper.appendChild( this.trigger );

    this.trigger.addEventListener( 'click', this.toggle );

  };

  /**
   * Destroy module.
   */
  PasswordMask.prototype.destroy = function() {
    this.trigger.removeEventListener( 'click', this.toggle );
    this.wrapper.parentNode.insertBefore( this.input, this.wrapper );
    this.wrapper.parentNode.removeChild( this.wrapper );
  };

  /**
   * Toggle input between password and text type.
   *
   * @param {Event} event
   */
  PasswordMask.prototype.toggle = function( event ) {
    const oldInput = this.input;

    // temporarily disable transitions
    oldInput.style.transition = "none";

    // clone the old input and toggle the type
    const newInput = oldInput.cloneNode();
    newInput.type = ( oldInput.type === 'password' ) ? 'text' : 'password';
    newInput.value = '';

    // insert the new input and remove the old one
    oldInput.insertAdjacentElement( 'afterend', newInput );
    oldInput.remove();

    // add value after restoring focus on the input,
    // so that position of cursor is preserved
    newInput.focus();
    newInput.value = oldInput.value;

    // restore transitions
    newInput.style.transition = null;

    this.input = newInput;

    // update title of trigger element
    this.trigger.title = this.getTriggerTitle();

    event.preventDefault();
  };

  /**
   * Get the title attribute of the trigger element.
   */
  PasswordMask.prototype.getTriggerTitle = function() {
    if ( this.input.type === 'password' ) {
      return this.settings.strings.show[ this.settings.lang ];
    }
    return this.settings.strings.hide[ this.settings.lang ];
  };

  global.PasswordMask = PasswordMask;

})( window );
